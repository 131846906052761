import {create} from '@storybook/theming/create'
import logo from './public/logo-horizontal.svg'

export default create({
  base: 'light',
  brandTitle: 'DATS 24',
  brandUrl: 'https://dats24.be',
  brandImage: logo,
  brandTarget: '_self',
})
